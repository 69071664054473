import React from 'react';
import { useTranslation } from 'react-i18next';
import materials from 'assets/t_materials.jpg';
import bracelets from 'assets/t_bracelets.jpg';
import movements from 'assets/t_movements.jpg';
import pictos from 'assets/t_pictos.jpg';
import pic from 'assets/t_PIC.jpg';
import quality from 'assets/t_quality.jpg';
import what from 'assets/t_what.jpg';

export function getFileUrl(name, language) {
  return '/files/' + name + '_' + language + '.pdf';
}

function TechnicalFeaturesPage() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <div className="technical links-page">
      <div className="container">
        <div className="grid">
          <div className="col-2 m-col-1">
          </div>
          <div className="col-2 m-col-3">
            <h1>{t('Technical features')}</h1>
            <h2>{t('technical.subtitle')}</h2>
            <div className="links">
              <a download href={getFileUrl('movements', language)}>
                <img src={movements} alt=""/>
                {t('technical.movements')}
              </a>
              <a download href={getFileUrl('materials', language)}>
                <img src={materials} alt=""/>
                {t('technical.materials')}
              </a>
              <a download href={getFileUrl('pictograms', language)}>
                <img src={pictos} alt=""/>
                {t('technical.pictos')}
              </a>
              <a download href={getFileUrl('pic_system', language)}>
                <img src={pic} alt=""/>
                {t('technical.pic')}
              </a>
              <a download href={getFileUrl('bracelet_adjustment', language)}>
                <img src={bracelets} alt=""/>
                {t('technical.bracelet')}
              </a>
              <a download href={getFileUrl('quality_control', language)}>
                <img src={quality} alt=""/>
                {t('technical.quality')}
              </a>
              <a download href={getFileUrl('what_to_do_if', language)}>
                <img src={what} alt=""/>
                {t('technical.what')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechnicalFeaturesPage
