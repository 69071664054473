import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './fonts.css';
import './index.css';
import ServiceWorkerLoader from 'utils/ServiceWorkerLoader';
import App from './App';
import { HttpError } from 'utils/Errors';

if (['stage', 'production'].includes(process.env.REACT_APP_SENTRY_ENVIRONMENT)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing({
      tracingOrigins: [
        process.env.REACT_APP_PUBLIC_URL,
      ],
    })],
    beforeSend(event, hint) {
      if (hint.originalException instanceof HttpError && hint.originalException.code === 503) {
        return null;
      }
      return event;
    },
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ServiceWorkerLoader key="swl"/>
    <App key="app"/>
  </React.StrictMode>
);
