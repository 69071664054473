import React, { useContext, useRef, useState } from 'react';
import MessageBox from 'components/MessageBox';
import logo from 'logo.svg';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner'
import fetchWithTimeout from 'utils/fetchWithTimeout';
import Storage from 'utils/Storage';
import AppContext from 'utils/AppContext';
import { t } from 'i18next';

function connect(login, password) {
  return fetchWithTimeout(process.env.REACT_APP_EXTRANET_URL + '/api/auth/login', {
    timeout: 20000,
    method: 'POST',
    body: JSON.stringify({
      login: login,
      password: password,
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  });
}

function LoginForm(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    login: false,
    password: false,
    global: false
  });
  const loginInput = useRef();
  const passInput = useRef();
  const { setToken } = useContext(AppContext);

  const onSubmit = (e) => {
    e.preventDefault();
    let hasErr = false;
    const login = loginInput.current.value.trim();
    const password = passInput.current.value.trim();

    const newErrs = {
      login: false,
      password: false,
      global: false
    };
    if (login.length < 1) {
      hasErr = true;
      newErrs.login = t('This field is mandatory');
    }
    if (password.length < 1) {
      hasErr = true;
      newErrs.password = t('This field is mandatory');
    }

    const setGlobalError = (message) => {
      setErrors({
        login: true,
        password: true,
        global: message || t('An unknown error has occurred, please try again later or contact our support if the error persists.')
      });
      setLoading(false);
    };

    if (hasErr) {
      setErrors(newErrs);
    }
    else {
      setLoading(true);
      connect(login, password).then(res => {
        if (res.ok) {
          res.json().then((result) => {
            fetchWithTimeout(process.env.REACT_APP_EXTRANET_URL + '/api/user/profile', {
              timeout: 20000,
              headers: new Headers({
                'Authorization': 'Bearer ' + result.token,
              })
            }).then(res2 => {
              if (res2.ok) {
                res2.json().then(result2 => {
                  Storage.setItem('token', result2.token, () => {
                    setToken(result2.token);
                    window.location = '/';
                    setLoading(false);
                  }, true);
                });
              }
              else {
                setGlobalError();
              }
            }).catch(() => { setGlobalError(); });
          });
        }
        else if (res.status === 401) {
          setGlobalError(t`Invalid username or password, please try again.`);
        }
        else if (res.status === 422) {
          res.json().then((result) => {
            let hasFieldError = false;
            const errs = {
              login: false,
              password: false,
              global: false
            };
            if ('login' in result.errors) {
              errs.login = result.errors.login.join(' ');
              hasFieldError = true;
            }
            if ('password' in result.errors) {
              errs.password = result.errors.password.join(' ');
              hasFieldError = true;
            }
            if (hasFieldError) {
              setErrors(errs);
              setLoading(false);
            }
            else {
              setGlobalError();
            }
          });
        }
        else {
          setGlobalError();
        }
      }).catch((err) => {
        setGlobalError();
      });
    }
  };
  return (
    <form className="form loader" onSubmit={onSubmit}>
      { loading && (
        <Spinner />
      ) }
      { errors.global && typeof errors.global === 'string' && (
        <div className="error-message">{ errors.global }</div>
      ) }
      <div className={'form-element' + (errors.login ? ' error' : '')}>
        <label htmlFor="login">{ t('Login') }*</label>
        <input ref={loginInput} type="text" name="login" id="login" />
        { errors.login && typeof errors.login === 'string' && (
          <div className="error-message">{ errors.login }</div>
        ) }
      </div>
      <div className={'form-element' + (errors.password ? ' error' : '')}>
        <label htmlFor="password">{ t('Password') }*</label>
        <input ref={passInput} type="password" name="password" id="password" />
        { errors.password && typeof errors.password === 'string' && (
          <div className="error-message">{ errors.password }</div>
        ) }
      </div>
      <input type="submit" value={t('Login')} />
    </form>
  );
}

function LoginPage(props) {
  return (
    <div className="base">
      <header>
        <nav className="navbar">
          <div className="container">
            <div className="menu-icons">
              <a href="/"><img className="logo" src={logo} alt="TISSOT" /></a>
            </div>
          </div>
        </nav>
      </header>
      <div className="main">
        <MessageBox type="info" context="full">
          <p>{ t('You are not logged in. Please login below with your extranet account.') }</p>
          <LoginForm />
          <div className="mt-2">
            <a target="_blank" rel="noreferrer" className="simplelink" href={process.env.REACT_APP_EXTRANET_URL + '/forgot-password'}>
              { t('Forgot your password?') }
            </a>
          </div>

        </MessageBox>
      </div>
      <footer>
        © TISSOT SA - SWISS WATCHES SINCE 1853 - ALL RIGHT RESERVED
      </footer>
    </div>
  );
}

export default LoginPage;
