import React from 'react';
import { useTranslation } from 'react-i18next';
import linkto from 'assets/s_linkto.jpg';
import customer from 'assets/t_customer.jpg';
import spare from 'assets/t_spare.jpg';
import services from 'assets/t_services.jpg';
import { getFileUrl } from './TechnicalFeaturesPage';

function StrapPage() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <div className="customer-services links-page">
      <div className="container">
        <div className="grid">
          <div className="col-2 m-col-1">
          </div>
          <div className="col-2 m-col-3">
            <h1>{t('Customer services')}</h1>
            <h2>{t('straps.subtitle')}</h2>
            <div className="links">
              <a
                href={process.env.REACT_APP_CS_BASE_URL}
                rel="noopener noreferrer"
                target="_blank">
                <img src={linkto} alt=""/>
                {t('Order spare parts components')}
              </a>
              <a download href={getFileUrl('customer_service', language)}>
                <img src={customer} alt=""/>
                {t('technical.customer')}
              </a>
              <a download href={getFileUrl('spare_parts_availability', language)}>
                <img src={spare} alt=""/>
                {t('technical.spare')}
              </a>
              <a download href={getFileUrl('type_of_services', language)}>
                <img src={services} alt=""/>
                {t('technical.services')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrapPage
