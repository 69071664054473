/* eslint-disable react/no-danger */
import React from 'react';
import AppContext from 'utils/AppContext';
import { withTranslation } from 'react-i18next';
import aboutHead2 from 'assets/about-head2.jpg';
import tradition from 'assets/tradition.jpg';
import sports3 from 'assets/sports3.jpg';
import sports4 from 'assets/sports4.jpg';
import sports5 from 'assets/sports5.jpg';
import history1 from 'assets/history1.jpg';
import swiss from 'assets/swiss.jpg';
import swissness1 from 'assets/swissness1.jpg';
import swissness2 from 'assets/swissness2.jpg';
import swissness3 from 'assets/swissness3.jpg';
import swissness4 from 'assets/swissness4.jpg';
import ambassadors_liu from 'assets/ambassadors_liu.jpg';
import ambassadors_huang from 'assets/ambassadors_huang.jpg';
import ambassadors_gong from 'assets/ambassadors_gong.jpg';
import ambassadors_lee from 'assets/ambassadors_lee.jpg';
import ambassadors_damian from 'assets/ambassadors_damian.jpg';
import ambassadors_sabrina from 'assets/ambassadors_sabrina.jpg';
import ambassadors_tony from 'assets/ambassadors_tony.jpg';
import ambassadors_enea from 'assets/ambassadors_enea.jpg';
import ambassadors_primoz from 'assets/ambassadors_primoz.jpg';
import ambassadors_jolanda from 'assets/ambassadors_jolanda.jpg';
import AboutPartners from 'components/AboutPartners';
import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';
import 'aos/dist/aos.css';

class AboutTissotPage extends React.Component {

  componentDidMount() {
    AOS.init({
      duration: 400,
      delay: 300
    });
    this.scroll = new SmoothScroll(null, {
      updateURL: false,
      offset: 100,
    });
    if (this.props.match.params.section) {
      setTimeout(() => this.scrollTo(this.props.match.params.section), 100);
    }
  }

  scrollTo(section) {
    const anchor = document.querySelector('#' + section);
    if (anchor) this.scroll.animateScroll(anchor);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.section) {
      this.scrollTo(this.props.match.params.section);
    }
  }

  render() {
    const { t } = this.props;
    const { countries, country } = this.context;
    return (
      <div id="about" className="about-tissot">
        <div className="head negative">
          <div className="bg">
            <div className="image"></div>
          </div>
          <div className="container grid">
            <div className="col-2"></div>
            <div className="col-2">
              <h1>{t('About Tissot')}</h1>
              <div className="chapo">{t('about.chapo')}</div>
              <div
                className="chapo-text text-base"
                dangerouslySetInnerHTML={{ __html: t('about.chapo2') }}>
              </div>
            </div>
          </div>
        </div>
        <div id="innovator_by_tradition" className="innovators page-section negative">
          <div className="content">
            <div className="container">
              <div className="grid">
                <div className="col-2">
                  <h2 data-aos="fade-left">{t('Innovators by tradition')}</h2>
                  <h3>{t('about.innovators.subtitle')}</h3>
                  <div className="grid">
                    <div>
                      <div
                        className="text-base"
                        dangerouslySetInnerHTML={{ __html: t('about.innovators.text') }}>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={aboutHead2} alt="" data-aos="flip-left" />
                </div>
                <div>
                  <img className="tradition" src={tradition} alt="" data-aos="flip-left" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="swissness" className="swissness page-section">

          <div className="container">
            <div className="grid">
              <div>
              </div>
              <div className="col-3">
                <h2
                  className="colored"
                  dangerouslySetInnerHTML={{ __html: t('about.swissness.title') }}></h2>
              </div>
            </div>
            <div className="grid">
              <div>
                <div
                  className="chapo"
                  dangerouslySetInnerHTML={{ __html: t('about.swissness.chapo') }}>
                </div>
              </div>
              <div className="col-text">

                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: '<img src=' + swiss + ' class="swiss-2" alt="" />' + t('about.swissness.text') }}>
                </div>
              </div>
              <div className="col-2">
                <img src={swissness2} alt="" data-aos="flip-left" />
                <div className="grid">
                  <div></div>
                  <div>
                    <div className="legend">{t('about.swissness.note2')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-3">
              <div>
                <div className="img">
                  <img src={swissness1} alt="" data-aos="flip-left" />
                  <div className="legend">{t('about.swissness.note1')}</div>
                </div>
              </div>
              <div>
                <div className="img">
                  <img src={swissness3} alt="" data-aos="flip-left" />
                  <div className="legend">{t('about.swissness.note3')}</div>
                </div>
              </div>
              <div>
                <div className="img">
                  <img src={swissness4} alt="" data-aos="flip-left" />
                  <div className="legend">{t('about.swissness.note4')}</div>
                </div>
              </div>
              <div
                className="text-base"
                dangerouslySetInnerHTML={{ __html: t('about.swissness.text2') }}>
              </div>
            </div>
          </div>
        </div>
        {!countries[country].hide_yt_video && (
          <div id="history" className="history page-section negative padding">
            <div className="container">
              <div className="grid bottom">
                <div className="col-2">
                  <h2 className="colored" dangerouslySetInnerHTML={{ __html: t('about.history.subtitle') }}></h2>
                  <div className="img-history">
                    <img src={history1} alt="" data-aos="fade-left" />
                  </div>
                </div>
                <div className="col-2">
                  <div className="video">
                    <iframe
                      title="video"
                      width="652"
                      height="406"
                      src={`https://www.youtube.com/embed/${t('about.history.videoUrl')}?rel=0&modestbranding=1`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div id="sports" className="sports page-section negative padding">
          <div className="head">
            <div className="img img-1"></div>

            <div className="content">
              <h2
                className="colored"
                dangerouslySetInnerHTML={{ __html: t('about.sports.subtitle') }}></h2>
              <div
                className="text-base"
                dangerouslySetInnerHTML={{ __html: t('about.sports.text') }}>
              </div>
            </div>
            <div className="img img-2"></div>
          </div>
        </div>
        <div className="sports page-section padding">
          <div className="container">
            <div className="grid">
              <div className="hide-small">
                <img src={sports3} alt="" data-aos="fade-right" />
              </div>
              <div className="hide-small">
                <img className="mb-3" src={sports4} alt="" data-aos="fade-right" />
                <img src={sports5} alt="" data-aos="fade-right" />
              </div>
              <div className="col-2">
                <AboutPartners />
              </div>
            </div>
          </div>
        </div>
        <div id="ambassadors" className="ambassadors page-section negative padding">
          <div className="container">
            <div className="grid">
              <div className="col-2"></div>
              <div className="col-2">
                <h2>{t('TISSOT ambassadors')}</h2>
              </div>
              <div></div>
            </div>
            <div className="grid mb-3">
              <div className="col-2">
                <img src={ambassadors_liu} alt="" data-aos="fade-right" />
              </div>
              <div>
                <h3 className="colored">{t('about.ambassadors.liu.firstname')} <strong>{t('about.ambassadors.liu.lastname')}</strong></h3>
                <h4>{t('about.ambassadors.liu.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.liu.desc') }}>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div className="grid mb-3">
              <div></div>
              <div className="order-2">
                <h3 className="colored">
                  {t('about.ambassadors.huang.firstname')} <strong>{t('about.ambassadors.huang.lastname')}</strong>
                </h3>
                <h4>{t('about.ambassadors.huang.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.huang.desc') }}>
                </div>
              </div>
              <div className="col-2 order-1">
                <img src={ambassadors_huang} alt="" data-aos="fade-right" />
              </div>
            </div>
            <div className="grid mb-3">
              <div className="order-2">
                <h3 className="colored">
                  {t('about.ambassadors.gong.firstname')} <strong>{t('about.ambassadors.gong.lastname')}</strong>
                </h3>
                <h4>{t('about.ambassadors.gong.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.gong.desc') }}>
                </div>
              </div>
              <div className="img-extend order-1">
                <img src={ambassadors_gong} alt="" data-aos="fade-right" />
              </div>
              <div className="order-4">
                <h3 className="colored">
                  {t('about.ambassadors.lee.firstname')} <strong>{t('about.ambassadors.lee.lastname')}</strong>
                </h3>
                <h4>{t('about.ambassadors.lee.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.lee.desc') }}>
                </div>
              </div>
              <div className="img-extend order-3">
                <img src={ambassadors_lee} alt="" data-aos="fade-left" />
              </div>
            </div>
            <div className="grid mb-3">
              <div className="order-2">
                <h3 className="colored">
                  {t('about.ambassadors.damian.firstname')} <strong>{t('about.ambassadors.damian.lastname')}</strong>
                </h3>
                <h4>{t('about.ambassadors.damian.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.damian.desc') }}>
                </div>
              </div>
              <div className="col-3 order-1">
                <img src={ambassadors_damian} alt="" data-aos="fade-right" />
              </div>
            </div>
            <div className="grid mb-3">
              <div className="col-2"></div>
              <div className="order-4">
                <h3 className="colored">
                  {t('about.ambassadors.sabrina.firstname')} <strong>{t('about.ambassadors.sabrina.lastname')}</strong>
                </h3>
                <h4>{t('about.ambassadors.sabrina.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.sabrina.desc') }}>
                </div>
              </div>
              <div className="img-extend order-3">
                <img src={ambassadors_sabrina} alt="" data-aos="fade-left" />
              </div>
            </div>
            <div className="grid mb-3">
              <div className="col-2">
                <img src={ambassadors_tony} alt="" data-aos="fade-right" />
              </div>
              <div>
                <h3 className="colored">{t('about.ambassadors.tony.firstname')} <strong>{t('about.ambassadors.tony.lastname')}</strong></h3>
                <h4>{t('about.ambassadors.tony.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.tony.desc') }}>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div className="grid mb-3">
              <div className="order-2">
                <h3 className="colored">
                  {t('about.ambassadors.enea.firstname')} <strong>{t('about.ambassadors.enea.lastname')}</strong>
                </h3>
                <h4>{t('about.ambassadors.enea.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.enea.desc') }}>
                </div>
              </div>
              <div className="img-extend order-1">
                <img src={ambassadors_enea} alt="" data-aos="fade-right" />
              </div>
              <div className="order-4">
                <h3 className="colored">
                  {t('about.ambassadors.primoz.firstname')} <strong>{t('about.ambassadors.primoz.lastname')}</strong>
                </h3>
                <h4>{t('about.ambassadors.primoz.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.primoz.desc') }}>
                </div>
              </div>
              <div className="img-extend order-3">
                <img src={ambassadors_primoz} alt="" data-aos="fade-left" />
              </div>
            </div>
            <div className="grid mb-3">
              <div></div>
              <div className="order-2">
                <h3 className="colored">
                  {t('about.ambassadors.jolanda.firstname')} <strong>{t('about.ambassadors.jolanda.lastname')}</strong>
                </h3>
                <h4>{t('about.ambassadors.jolanda.role')}</h4>
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: t('about.ambassadors.jolanda.desc') }}>
                </div>
              </div>
              <div className="col-2 order-1">
                <img src={ambassadors_jolanda} alt="" data-aos="fade-right" />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
AboutTissotPage.contextType = AppContext;

export default withTranslation()(AboutTissotPage);
