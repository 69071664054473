import React from 'react';
import Context from 'models/Context';
import logo from 'logo.svg';
import Spinner from 'components/Spinner';
import BasePage from 'pages/BasePage';
import AppContext, { appActions } from 'utils/AppContext';
import LoginPage from './LoginPage';
import { HttpError } from 'utils/Errors';

class LoadingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      /* eslint-disable-next-line react/no-unused-state */
      data: null,
      img: Math.ceil(Math.random() * 3),
      loadFail: false
    };
  }

  fetchData(appContext) {
    const context = new Context(appContext);
    return context.fetch(this, null, {
      onError: (response) => {
        if (response.status !== 500 && response.status !== 403 && response.status !== 401) {
          appActions.setError(new HttpError(response.status, response.status, response));
        }
        this.setState({ loadFail: true });
      }
    });
  }

  componentDidMount() {
    const appContext = this.context;
    if (this.fetchPromise) {
      return;
    }

    this.fetchPromise = this.fetchData(appContext);
  }

  render() {
    const { isLoaded, loadFail } = this.state;

    if (loadFail && !this.context.error) {
      return <LoginPage />;
    } else if (this.context.error) {
      throw this.context.error;
    } else if (!isLoaded) {
      return (
        <div className={`loading-page img-${this.state.img}`}>
          <img className="logo" src={logo} alt="TISSOT" /><Spinner color="white" />
        </div>
      );
    } else {
      return (
        <BasePage />
      );
    }
  }
}
LoadingPage.contextType = AppContext;

export default LoadingPage;
